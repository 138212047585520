import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchRevisers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/revisers', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchReviser(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/reviser/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addReviser(ctx, userData) {
            return new Promise((resolve, reject) => {

                const payload = {
                    names: userData.names,
                    surnames: userData.surnames,
                    email: userData.email
                }

                axios
                    .post('/reviser/create', payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateReviser(ctx, { id, reviserData }) {
            return new Promise((resolve, reject) => {

                const payload = {
                    user_id: reviserData.id,
                    names: reviserData.names,
                    surnames: reviserData.surnames,
                    email: reviserData.email,
                    password: reviserData.password
                }

                axios
                    .put(`/reviser/${id}/update`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteReviser(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/reviser/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}
import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';

export default function useRevisersList() {
    // Use toast
    const toast = useToast();
    
    const refReviserListTable = ref(null);
    
    // Table Handlers
    let tableColumns = [
        { key: 'user.fullName', sortable: true, label: 'Nombre completo' },
        { key: 'user.email', sortable: true, label: 'Correo electrónico' },
        { key: 'forms_count', sortable: true, label: 'Cantidad de formularios asociados' },
        { key: 'actions', label: 'Opciones' }
    ];
    
    const perPage = ref(10);
    const totalUsers = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(false);
    
    const dataMeta = computed(() => {
        const localItemsCount = refReviserListTable.value ? refReviserListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalUsers.value,
        }
    });
    
    const refetchData = () => {
        refReviserListTable.value.refresh();
    };
    
    watch([currentPage, perPage, searchQuery], () => {
        refetchData();
    });
    
    const fetchRevisers = (ctx, callback) => {
        store
        .dispatch('app-reviser/fetchRevisers', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value
        })
        .then(response => {
            const { users, total } = response.data;
            callback(users);
            totalUsers.value = total;
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error al obtener la lista de revisores',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            });
        })
    };
    
    return {
        fetchRevisers,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refReviserListTable,
        
        refetchData
    }
}
